import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

import Logo from "./components/Logo";
import { ReactComponent as FacebookLink } from "./assets/facebook.svg";

const App = () => {
  const theme = useTheme();

  return (
    <Box
      bgcolor="primary.main"
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={4}>
        <Logo />
      </Box>
      <Typography color="textPrimary">
        <Grid container={true}>
          <Grid item={true} xs={3}>
            Email:{" "}
          </Grid>
          <Grid item={true} xs={6}>
            <Link href="mailto:paul@warwood.construction" color="secondary">
              paul@warwood.construction
            </Link>
          </Grid>
        </Grid>
        <Box mt={0.5}>
          <Grid container={true}>
            <Grid item={true} xs={3}>
              Phone:{" "}
            </Grid>
            <Grid item={true} xs={6}>
              <Link href="tel:07719327920" color="secondary">
                07719 327920
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <Link href="https://www.facebook.com/pwarwood/" target="_blank">
            <FacebookLink width={theme.spacing(15)} />
          </Link>
        </Box>
      </Typography>
      <Box position="absolute" bottom={theme.spacing(2)}>
        <Typography color="textPrimary" variant="caption">
          &copy; 2021, Warwood Construction
        </Typography>
      </Box>
    </Box>
  );
};

export default App;
