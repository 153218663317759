import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#143981",
    },
    secondary: {
      main: "#60E315",
    },
  },
});

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line no-console
  console.log(theme);
}

export default theme;
