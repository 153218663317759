import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  impactFont: {
    fontFamily: "Impact",
    fontStyle: "italic",
    textTransform: "uppercase",
  },
  outlinedFont: {
    color: theme.palette.primary.main,
    fontSize: "1.2em",
    textShadow: `
   -1px -1px 0 ${theme.palette.text.primary},
    1px -1px 0 ${theme.palette.text.primary},
    -1px 1px 0 ${theme.palette.text.primary},
     1px 1px 0 ${theme.palette.text.primary}`,
  },
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" component="h1">
        <Box className={classnames(classes.impactFont, classes.outlinedFont)}>
          Warwood
        </Box>
      </Typography>
      <Typography variant="h3" component="h1" color="primary">
        <Box
          className={classes.impactFont}
          bgcolor="text.primary"
          ml={1}
          pr={1.5}
        >
          Construction
        </Box>
      </Typography>
    </>
  );
};

export default Logo;
